<template>
  <div
    class="relative banner group"
    :class="props.isMainBanner ? 'pt-[64px] lg:pt-[96px]' : 'mt-0 lg:mx-0'"
    :style="styles"
  >
    <div
      v-if="props.overlay"
      class="absolute inset-0 z-20 bg-black opacity-50"
    />
    <!-- image as background -->
    <template v-if="props.imageAsBackground === true && props.image">
      <div
        v-show="!isImageLoaded"
        class="!z-20 banner__background filter blur-0 bg-black"
      ></div>
      <NuxtImg
        :alt="props.headline"
        :src="props.image"
        format="webp"
        width="100%"
        height="100%"
        fit="crop"
        :sizes="
          props.multiple
            ? 'xs:100vw sm:100vw md:100vw lg:50vw'
            : 'xs:100vw sm:100vw md:100vw lg:100vw'
        "
        :preload="props.areaIndex < 2 && props.itemIndex < 2"
        :loading="props.areaIndex < 2 && props.itemIndex < 2 ? '' : 'lazy'"
        @load="onLoadImage"
        class="w-full banner__background"
      />
      <div class="banner__cover" />
    </template>
    <template v-if="props.backgroundVideo">
      <video
        ref="backgroundVideo"
        loop
        preload="metadata"
        playsinline
        muted
        loading="lazy"
        class="absolute inset-0 z-10 object-cover w-full max-w-full min-w-full min-h-full"
      >
        <source :src="props.backgroundVideo" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </template>
    <div
      class="banner__container"
      :class="
        props.productSection
          ? 'product_page_container'
          : 'container px-6 xl:px-12 z-30'
      "
    >
      <!-- <XyzTransition :appear-visible="appearVisible" duration="auto"> -->
      <div
        class="banner__inner"
        :class="[
          props.productSection
            ? 'h-auto pb-0 product-page'
            : props.isMainBanner
            ? 'h-[416px] md:h-[484px] xl:h-[624px]'
            : 'h-[480px] md:h-[580px] xl:h-[720px] ',
          !props.productSection ? middleContentAlignment : '',
          !props.imageAsBackground &&
          !props.productSection &&
          !props.backgroundVideo
            ? 'mt-14 '
            : '',
        ]"
      >
        <div xyz="fade small stagger ease-out-back" :class="textAlignmentClass">
          <component
            :is="props.headlineTag"
            :style="
              props.textShadows && props.isTextShadow
                ? `text-shadow: ${props.textShadows.x_offset}px ${props.textShadows.y_offset}px ${props.textShadows.blur}px ${props.textShadows.converted_color}`
                : ''
            "
            class="leading-[40px] mb-0 md:leading-[36px] font-bold italic font-ff-good-pro-cond tracking-[0.34px] md:tracking-[-0.56px] xyz-nested text-[32px] sm:text-3xl md:text-[44px] lg:text-[46px] 2xl:leading-[54px] 1.5xl:tracking-[-0.52px] xl:text-[52px] xl:!leading-[56px] 2xl:text-[48px] 2.5xl:text-[60px] 3xl:text-[65px]"
          >
            <slot name="headline">
              {{ props.headline }}
            </slot>
          </component>
          <div
            v-if="props.subheadline || hasSlot('subheadline')"
            :style="
              props.textShadows && props.isTextShadow
                ? `text-shadow: ${props.textShadows.x_offset}px ${props.textShadows.y_offset}px ${props.textShadows.blur}px ${props.textShadows.converted_color}`
                : ''
            "
            class="font-medium mt-1 leading-[30px] font-ff-good-pro-cond italic xyz-nested text-[22px] md:leading-[40px] lg:text-[36px] h-[24px] md:h-[32px] min-w-[200px] xl:min-w-[360px] lg:min-w-[280px]"
          >
            <slot name="subheadline">
              {{ props.subheadline }}
            </slot>
          </div>

          <div
            class="mt-[6px] lg:mt-3 text-base font-bold italic leading-[26px] banner__links font-ff-good-pro-cond md:!leading-7 lg:!text-[22px]"
          >
            <NuxtLink
              v-for="(link, linkIndex) in props.links"
              :key="linkIndex"
              :to="link.to"
              :external="link.external"
              class="italic font-bold xyz-nested font-ff-good-pro-cond"
              :style="
                props.textShadows && props.isTextShadow
                  ? `text-shadow: ${props.textShadows.x_offset}px ${props.textShadows.y_offset}px ${props.textShadows.blur}px ${props.textShadows.converted_color}`
                  : 'text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.71);'
              "
            >
              {{ link.text }}
            </NuxtLink>
          </div>
        </div>
        <a
          v-if="props.video"
          :href="'#'"
          class="mt-3"
          style="filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.8))"
          @click.prevent="openVideoModal"
        >
          <YoutubeIcon class="w-12 h-12 text-white fill-current" />
        </a>

        <NuxtImg
          v-if="!props.imageAsBackground && props.image"
          :src="props.image"
          format="webp"
          fetchpriority="high"
          loading="lazy"
          width="100%"
          height="100%"
          preload
          fit="cover"
          sizes="xs:100vw sm:100vw md:100vw lg:100vw 100vw"
          :alt="props.headline"
          :class="[
            'mx-auto mt-8 banner__image',
            props.productSection
              ? 'w-full'
              : 'w-auto max-h-[450px] md:max-h-[550px] lg:max-h-[580px] xl:max-h-[710px]',
          ]"
        />
        <span />
        <slot />
      </div>
      <!-- </XyzTransition> -->
    </div>
  </div>
</template>

<script setup>
import YoutubeVideoModal from "~/modals/YoutubeVideoModal";
import { nextTick } from "vue";
import { useModal } from "vue-final-modal";
import YoutubeIcon from "~/assets/svg/heroicons/youtube.svg?component";
import { ContentLoader } from "vue-content-loader";

const props = defineProps({
  areaIndex: {
    type: Number,
    default: false,
  },
  itemIndex: {
    type: Number,
    default: false,
  },
  isMainBanner: {
    type: Boolean,
    default: false,
  },
  headlineTag: {
    type: String,
    default: "h4",
  },
  appearVisible: {
    type: Boolean,
    default: false,
  },
  headline: {
    type: String,
    default: null,
  },
  subheadline: {
    type: String,
    default: null,
  },
  links: {
    type: Array,
    default: () => [],
  },
  image: {
    type: String,
    default: null,
  },
  imageAsBackground: {
    type: Boolean,
    default: false,
  },
  // @TODO: temporary, remove and use video
  backgroundVideo: {
    type: String,
    default: null,
  },
  video: {
    type: String,
    default: null,
  },
  colors: {
    type: Object,
    default: () => ({
      background: "linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, #3a3939 100%)",
      text_color: "#FFFFFF",
    }),
  },
  productSection: {
    type: Boolean,
    default: false,
  },
  overlay: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: "center_middle",
  },
  isTextShadow: {
    type: Boolean,
    default: false,
  },
  textShadows: {
    type: Object,
    default: () => {},
  },
});

const slots = useSlots();
const isImageLoaded = ref(false);
const isMobile = ref(false);
const windowWidth = ref(0);

const styles = computed(() => {
  return {
    background: props.colors.background,
    color: props.colors.text_color,
  };
});

const textAlignmentClass = computed(() => {
  if (!props.imageAsBackground && !props.backgroundVideo) return "text-center";

  switch (props.position) {
    case "left_top":
      return "text-left";
    case "left_middle":
      return "text-left w-[430px]";
    case "left_bottom":
      return "text-left";
    case "center_top":
      return "text-center";
    case "center_middle":
      return "text-center";
    case "center_bottom":
      return "text-center";
    case "right_top":
      return "text-right";
    case "right_middle":
      return "text-right w-[430px]";
    case "right_bottom":
      return "text-right";

    default:
      return "text-center";
  }
});

const middleContentAlignment = computed(() => {
  if (!props.imageAsBackground && !props.backgroundVideo)
    return "flex justify-center items-center flex-col pt-[64px] md:pt-[80px] ";

  switch (props.position) {
    case "left_top":
      return "flex flex-col justify-start rtl:items-end ltr:items-start pt-16 md:pt-10";
    case "left_middle":
      return "flex flex-col justify-center rtl:items-end ltr:items-start";
    case "left_bottom":
      return "flex flex-col justify-end rtl:items-end ltr:items-start pb-[64px] md:pb-[80px]";
    case "center_top":
      return "flex flex-col justify-start items-center pt-16 md:pt-10 ";
    case "center_middle":
      return "flex flex-col justify-center items-center";
    case "center_bottom":
      return "flex flex-col justify-end  items-center pb-[64px] md:pb-[80px]";
    case "right_top":
      return "flex flex-col justify-start rtl:items-start ltr:items-end pt-16 md:pt-10";
    case "right_middle":
      return "flex flex-col justify-center rtl:items-start ltr:items-end";
    case "right_bottom":
      return "flex flex-col justify-end rtl:items-start ltr:items-end pb-[64px] md:pb-[80px]";

    default:
      return "flex flex-col justify-start items-center pt-16 md:pt-10 ";
  }
});

getSizes();
onMounted(async () => {
  await nextTick();
  if (process.browser) {
    const videos = document.querySelectorAll("video");

    if (videos.length >= 1) {
      const options = {
        rootMargin: "0px 0px -20% 0px",
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          // eslint-disable-line arrow-parens
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      }, options);

      Array.prototype.forEach.call(videos, (video) => {
        // eslint-disable-line arrow-parens
        observer.observe(video);
      });
    }
  }
});

const { open, close } = useModal({
  component: YoutubeVideoModal,
  attrs: {
    source: props.video,
  },
});
const openVideoModal = open;

function onLoadImage(result) {
  if (result) {
    isImageLoaded.value = true;
  }
}
function getSizes() {
  if (!import.meta.browser) return;
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 1023) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
}
function hasSlot(name = "default") {
  return !!slots[name] || !!slots.default[name];
}
</script>

<style lang="postcss">
.banner {
  @apply flex relative text-center overflow-hidden;

  video {
    aspect-ratio: 16/9;
  }
}

.banner__container.product_page_container {
  @apply w-1/2 mx-auto z-20 relative;
}

.banner__inner.product-page {
  @apply overflow-hidden pt-[60px] lg:pt-[93px] md:pb-5 lg:pb-12 flex flex-col justify-between;
}

.banner__cover {
  @apply absolute inset-0 z-10 w-full h-full;
}

.banner__coverlink {
  @apply absolute top-0 bottom-0 end-0 start-0 flex items-center justify-center text-white z-10;
}

.banner__links {
  @apply space-s-6;
}

.banner__links a {
  @apply text-lg text-white relative z-20 mt-1 leading-6 hover:text-primary-400 font-ff-good-pro-cond tracking-[0.18px] md:tracking-[0.2px] font-bold lg:leading-7 lg:text-[22px];
}

.banner__image {
  @apply max-w-full h-auto;
}

.banner__background {
  @apply absolute inset-0 w-full h-full object-cover z-10;
}

@media only screen and (max-width: 1920px) {
  .banner__container.product_page_container {
    width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .banner__container.product_page_container {
    width: 94%;
  }
}

@media only screen and (max-width: 768px) {
  .banner__container.product_page_container {
    @apply w-full;
  }
}

@media only screen and (max-width: 425px) {
  .banner__container.product_page_container {
    @apply w-full;
  }
}
</style>
